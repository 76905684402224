<template>
  <div>
    <Title title="Student FAQ" subtitle="Frequently Asked Questions" />
    <router-link to="/student-faq">&lt;&lt;</router-link>
  </div>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
  components: {
    Title
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';
</style>
