<template>
  <div class="title-div">
    <h1>{{ title }}</h1>
    <h2>{{ subtitle }}</h2>
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle']
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';

.title-div {
  padding: 4vh;
  h2 {
    margin-top: 1vh;
  }
}
</style>
