<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I find documentation such as AIP, ERSA, VFRG, charts and aircraft manuals?</h2>
      <p>
        All of these documents can be found under
        <router-link to="/student-resources">Student Resources</router-link>
        and you are strongly encouraged to read these throughout your flight training. Many
        of the
        <router-link to="/homework-sheets">Student Homework Sheets</router-link>
        contain references to these documents so that you can continue your flight training in the
        comfort of your own home.
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
</style>
